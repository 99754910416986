<template>
    <div id="timeout">

        <v-dialog v-model="modal_show" persistent max-width="600">
            <v-card>
                <v-card-title class="text-h5 text-center align-center justify-center">
                    Continue working?
                </v-card-title>



                <v-card-text class="text--black text--darken-4">

                    <div class="mx-auto text-center">
                        <v-progress-circular :size="125" :rotate="-90" class="mx-auto"
                                             :width="15"
                                             :value="modal_reaming_perc"
                                             color="primary">
                            {{ modal_reaming }}s
                        </v-progress-circular>
                    </div>
                    <br />
                    <div class="text-center">
                        Your session is about to expire and you will be redirected to the login page.
                        Click continue to keep working.
                    </div>
                </v-card-text>

                <v-card-actions class="align-center justify-center">
                    <v-btn color="primary" text @click="modal_continue">
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>


<style lang="scss" scoped>
</style>

<script>
    /* eslint-disable */
    export default {
        name: "timeout",
        props: ["seconds_to_warn", "seconds_to_final", "loginURL"],
        data() {
            return {
                x: "timeout this",
                yesModal: false,
                warnTimer: null,
                finalTimer: null,
                cnt: 0,
                modal_show: false
            };
        },
        methods: {
            modal_continue: function () {
                this.startup();
                this.modal_show = false;
            },
            close_modal_now: function () {
                this.modal_show = false;
            },
            checkFinal: function () {
                var self = this;
                this.finalTimer = window.setInterval(() => {
                    this.$store.commit(
                        "setTime_remaining_final_mills",
                        (this.$store.state.time_remaining_final_mills -= 1000)
                    );

                    //  console.log(this.$store.state.time_remaining_final_mills);

                    if (this.$store.state.time_remaining_final_mills <= 0) {
                        clearInterval(this.finalTimer);
                        this.finalTimer = null;
                        window.location.replace(self.loginURL);
                    }
                }, 1000);
            },
            checkWarn: function () {
                this.warnTimer = window.setInterval(() => {
                    this.$store.commit(
                        "setTime_remaining_warn_mills",
                        (this.$store.state.time_remaining_warn_mills -= 1000)
                    );

                    //       console.log(this.$store.state.time_remaining_warn_mills);

                    if (this.$store.state.time_remaining_warn_mills <= 0) {
                        this.modal_show = true;
                        console.log("check final to start");
                        clearInterval(this.warnTimer);
                        this.warnTimer = null;
                        this.checkFinal();
                    }
                }, 1000);
            },
            startup: function () {
                this.$store.commit(
                    "setTime_remaining_warn_mills",
                    parseInt(this.seconds_to_warn) * 1000
                );
                this.$store.commit(
                    "setTime_remaining_final_mills",
                    parseInt(this.seconds_to_final) * 1000
                );

                if (this.finalTimer != null) {
                    clearInterval(this.finalTimer);
                    this.finalTimer = null;
                }

                if (this.warnTimer != null) {
                    clearInterval(this.warnTimer);
                    this.warnTimer = null;
                }

                this.checkWarn();
            }
        },
        computed: {
            modal_reaming: function () {
                return (this.$store.state.time_remaining_final_mills / 1000).toString();
            },
            modal_reaming_perc: function () {
                //Obtain percentage based on total time
                let sec = (this.$store.state.time_remaining_final_mills / 1000);
                return (sec / this.seconds_to_final) * 100;
            }
        },
        mounted() {
            this.self = this;
            if (this.seconds_to_warn) {
                //chill timer for debug
                this.startup();
            }
        }
    };
</script>

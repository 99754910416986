<template>
    <Transition name="fade-transition">
        <div id="appdash" style="overflow-y:auto; max-height:93vh;">


            <h2 class="white--text mx-auto align-center text-center">Reno Police Department Apps</h2>

            <v-data-iterator :loading="true" :loading-text="loading_text" :items="app_list" :search="search_apps" :no-results-text="no_results_text" hide-default-footer disable-pagination class=" mx-auto white--text pa-5 mb-12" style="max-width:900px">
                <template v-slot:loading>


                    <v-row>


                        <v-col cols="12" md="12" lg="6" v-for="index in fakeLoadingCards" :key="index" class="">

                            <v-skeleton-loader elevation="6" class="pa-5 secondary" type="heading" color="secondary">
                            </v-skeleton-loader>
                            <v-skeleton-loader elevation="6"  type="list-item">
                            </v-skeleton-loader>


                        </v-col>



                    </v-row>

                </template>

                <template v-slot:header>

                    <v-card class="mb-5" elevation="6">
                        <v-text-field v-model="search_apps" color="primary"
                                      clearable
                                      flat
                                      solo
                                      hide-details
                                      prepend-inner-icon="$magnify"
                                      label="Filter apps by keyword(s)">

                        </v-text-field>
                    </v-card>

                </template>

                <template v-slot:default="props">
                    <v-row no-gutters>
                        <transition-group name="fade-transition" class="row">

                            <v-col v-for="(item,index) in props.items"
                                   :key="index" cols="12" md="12" lg="6">


                                <v-card elevation="12">

                                    <v-overlay absolute :value="item.isLoading">
                                        <v-progress-circular indeterminate :size="65" :width="5"
                                                             color="white"></v-progress-circular>
                                    </v-overlay>

                                    <v-card-title class="text-h6 text-md-h5 secondary white--text text-uppercase">
                                        <v-btn icon color="white" left width="25px" height="25px" class="mr-1" @click="info(item.appname, item.desc)">
                                            <v-icon>$info</v-icon>
                                        </v-btn>{{ item.appname }}
                                        <v-spacer></v-spacer>
                                        <v-btn v-if="item.isFavorite" icon color="yellow" left width="25px" height="25px" class="" @click="unFavoriteApp(item)">
                                            <v-icon>$staron</v-icon>
                                        </v-btn>
                                        <v-btn v-else icon color="white" left width="25px" height="25px" class="" @click="favoriteApp(item)">
                                            <v-icon>$staroff</v-icon>
                                        </v-btn>
                                    </v-card-title>

                                    <v-card-subtitle class="secondary white--text" style="word-break: break-all; padding-top: 5px;">
                                        <!--<v-btn icon color="white" left width="25px" height="25px" class="mr-1" @click="copy(item.url)">
                                            <v-icon>$clipboard</v-icon>
                                        </v-btn>{{ item.url }}-->
                                    </v-card-subtitle>

                                    <v-divider></v-divider>

                                    <v-card-actions>
                                        <v-btn outlined color="#2B5F2B" @click="open(item, item.url)" block><v-icon left>$exitToApp</v-icon>Open</v-btn>

                                    </v-card-actions>


                                </v-card>


                            </v-col>

                        </transition-group>
                    </v-row>
                </template>


            </v-data-iterator>



            <!--Desc dialog-->
            <v-dialog v-model="dialog" width="800px" scrollable eager>

                <v-card tile class="filter-card" max-height="400px" style="height: fit-content;">


                    <v-card-title class="secondary white--text">
                        <span @click="doSomething" class="text-h5 app_title">{{dialog_form.appname}}</span>
                    </v-card-title>

                    <v-card-text class="pa-0">
                        <v-container>

                            <div class="ma-2 report-container" v-if="dialog_form.desc">
                                <h3 class="pb-1">Description</h3>
                                <div>{{dialog_form.desc}}</div>
                            </div>

                            <div class=" ma-2 report-container" v-else>
                                <h3 class="pb-1">Description</h3>
                                <div> No app description is available for this app.</div>
                            </div>

                        </v-container>
                    </v-card-text>
                
                    <v-card-actions style="align-self: center;">
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="closeDialog" text class="pa-2 ma-2 text-center">Close</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>

                </v-card>

            </v-dialog>

        </div>
    </Transition>
</template>


<style lang="scss" scoped>

    @media only screen and (min-width:769px) {
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        ::-webkit-scrollbar-track {
            border-radius: 10px;
            background: rgba(255,255,255,0.1);
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: rgba(255,255,255,0.2);
        }

        ::-webkit-scrollbar-thumb:hover {
            background: rgba(255,255,255,0.4);
        }

        ::-webkit-scrollbar-thumb:active {
            background: rgba(255,255,255,.9);
        }
    }

    /* :) hehe */
    .app_title {
        position: relative;
        animation: bounce 0.5s;
    }

    @keyframes bounce {
        0% {
            top: 0;
        }

        50% {
            top: -10px;
        }

        100% {
            top: 0;
        }
    }

    </style>

<script>
    /* eslint-disable */
    import { bus_common } from "../mixins/bus_common";
    export default {
        name: "appdash",
        props: ["app_list", "tURL", "fakeLoadingCards"],
        mixins: [bus_common],
        data() {
            return {
                x: "fab ths",
                search_apps: "",
                no_results_text: "No matching apps were found.",
                loading_text: "Retrieving your current apps ... ",
                isMobile: this.$vuetify.breakpoint.mobile,
                dialog: false,
                dialog_form: {
                    appname: "",
                    desc: ""
                },
                done: false

            };
        },
        methods: {
            open(app, appURL) {
                app.isLoading = true;
                let transURL = appURL + "?token=" + this.tURL;
                //window.location.replace(transURL);
                window.location = transURL;

                setTimeout(() => {
                    app.isLoading = false;
                }, 5000)
            },

            copy(appURL) {

                var text = appURL
                const elem = document.createElement('textarea');
                elem.value = text;
                document.body.appendChild(elem);
                elem.select();
                document.execCommand('copy');
                document.body.removeChild(elem);

                this.swall_it("Link Copied!", "The app link has been copied to your clipboard", "success", null, null);

            },

            info(appname, desc) {
                this.dialog_form.appname = appname;
                this.dialog_form.desc = desc;
                const element = document.querySelector('.app_title');
                element.style.animation = 'none';
                this.dialog = true;
            },


            favoriteApp(app) {
                let callback = () => {
                    this.submitFavApp(app, true);
                }
                this.swall_diag("Favorite app?", "Are you sure you would like to favorite this app? This will place it at the top of your list.", "question", callback, "Yes", "No", null)
            },

            unFavoriteApp(app) {
                let callback = () => {
                    this.submitFavApp(app, false);
                }
                this.swall_diag("Unfavorite app?", "Are you sure you would like to unfavorite this app?", "question", callback, "Yes", "No", null)
            },

            submitFavApp(app, isfavoriting) {
                this.$emit('submitFavApp', { app: app, isfavoriting: isfavoriting })
            },

            closeDialog() {
                this.dialog = false;
            },

            doSomething() {
                const element = document.querySelector('.app_title');
                element.style.animation = 'none';
                element.offsetHeight; /* trigger reflow */
                element.style.animation = null;

            },
            clear_loadingapps() {
                this.$emit('clear_loadingapps');
                console.log("Clear loading apps on backbutton");
            }
        },

        computed: {

        },
        mounted() {
            //document.addEventListener("hashchange", this.clear_loadingapps(), false);
        },
        beforeDestroy() {
            //document.removeEventListener("hashchange", this.clear_loadingapps());
        }
    };
</script>

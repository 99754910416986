<template>
    <timeout :seconds_to_warn="600" :seconds_to_final="60" v-bind:loginURL="loginURL" v-if="showtimeout"></timeout>
</template>

<script>
    /* eslint-disable */
    import { bus_common } from "../mixins/bus_common";
    import timeout from "./timeout";
    export default {
        name: "auth",
        mixins: [bus_common],
        components: { timeout },
        data() {
            return {
                x: "auth this",
                refreshTimer: null,
                showtimeout: true,
                /*loginURL: 'https://localhost:44365/'*/
                loginURL: this.$store.state.appURL

            };
        },
        mounted() {

            let callback = () => { window.location.replace(self.loginURL); }
            this.showtimeout = true;
            var self = this;
            this.refreshTimer = window.setInterval(() => {
                if (
                    self.$store.state.user != undefined &&
                    self.$store.state.user.auth == true
                ) {
                    var form = { token: this.$store.state.user.token };


                    this.$axios.post('api/valtoken', form)
                        .then(response => {

                            var user = {
                                email: response.data.email,
                                date: this.$moment(response.data.date),
                                auth: true,
                                token: response.data.token,
                                roles: response.data.roles,
                                rpduserId: response.data.rpduserId,
                                local_roleId: response.data.local_roleId
                            };

                            this.$store.commit("setUser", user);

                        }).catch(error => {
                            if (error.response) {

                                this.$swal({
                                    title: "Login Error!",
                                    text: "You have not logged in or your login has timed out, you will be redirected to the login page.",
                                    icon: "error",
                                    confirmButtonText: "OK",
                                    confirmButtonColor: '#283593',
                                    timer: 4000,
                                    didClose: callback
                                });
                            }
                        });
                }
            }, 300000);
            //Check token evey 5m
        },
        created() {

            let callback = () => { window.location.replace(self.loginURL); }
            var qry_token = this.$route.query.token;
            var user_token = this.$store.state.user.token;
            var self = this;

            if (qry_token == undefined && user_token == "") {

                var self = this;
                this.$swal({
                    title: "Login Error!",
                    text: "You have not logged in or your login has timed out, you will be redirected to the login page.",
                    icon: "error",
                    confirmButtonText: "OK",
                    confirmButtonColor: '#283593',
                    timer: 4000,
                    didClose: callback
                });
            }

            else {
                //validate toke, query takes precendent
                var token = qry_token != undefined ? qry_token : user_token;

                var form = {
                    token: token
                };

                var self = this;

                this.$axios.post('api/valtoken', form)
                    .then(response => {
                        var user = {
                            email: response.data.email,
                            date: this.$moment(response.data.date),
                            auth: true,
                            token: response.data.token,
                            roles: response.data.roles,
                            rpduserId: response.data.rpduserId,
                            local_roleId: response.data.local_roleId
                        };

                        this.$store.commit("setUser", user);

                    }).catch(error => {
                        if (error.response) {

                            this.$swal({
                                title: "Login Error!",
                                text: "You have not logged in or your login has timed out, you will be redirected to the login page.",
                                icon: "error",
                                confirmButtonText: "OK",
                                confirmButtonColor: '#283593',
                                timer: 4000,
                                didClose: callback
                            });
                        }
                    });
            }


        }
    };
</script>
